import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "stiff-joints-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How my 58 year old bones got 90% relief from stiff joints`}</h1>
    <p>{`OK, so technically it wasn't my bones that got relief, it was my joints.`}</p>
    <h1>{`Executive summary`}</h1>
    <p>{`The product I found relief from was
`}<img parentName="p" {...{
        "src": "/files/stiff-joints-Jamieson.jpg",
        "alt": "Jamieson's \"Glucosamine chondroitin MSM\""
      }}></img>{`
. I am not paid anything by them to recommend their product, and this was not a scientific test, I didn't try any other brands, I didn't try any other formulations.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`500mg Glucosamine sulfate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`400mg Chondroitin sulfate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`400mg Methylsulfonylmethane (MSM)`}</p>
      </li>
    </ul>
    <p>{`I got noticeable relief a few hours after the 1st pill. I thought it was the 'placebo' effect - but the effects stayed even when I forgot about them. I had been told not to expect results for 30 days by the "infallible internet" and the writing on the product said: "Use for a minimum of 1 month to see beneficial effects."`}</p>
    <p>{`As it probably says in Dr. Seuss' "Green eggs and ham" ... "Try them, try them, you may see..."`}</p>
    <p>{`If you are vegetarian or vegan, this product won't meet your rules. (shrimp and crab exoskeletons and bovine cartilage)`}</p>
    <h1>{`The details`}</h1>
    <p>{`Glucosamine, best from glucosamine sulfate, chondroitin and MSM review`}</p>
    <p>{`In 2020.08 a comment from my 31 year old daughter commented about how 'old' she was feeling when she got up that day,
that she was so stiff.`}</p>
    <p>{`My thought was that for as long as I could remember (years not decades) my knees and arm joints especially,
but also other joints, were always very stiff after I was stationary for more than 20 minutes, sometimes less. `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`When I would drive to the closest city, a 20 minute drive, I would be stiff getting out and for the first 5 or 6 steps.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When I sit in a couch, such as watching TV (not one of my favorite activities) and I try to get up when the commercial comes, I always found myself very stiff until I had walked at least a few steps.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When I stood at a counter for more than 20 minutes, my first couple steps were stiff.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`When I got down on the floor to play with my kids, I would be a little stiff getting down (because I hadn't been bending that way for hours), then I'd be stiff getting back up. If I played with them for a few minutes, getting up and down, I would loosen up and then forget about how stiff I had been.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`*hen I bent over to pick something up off the floor, i'd be stiff the first 4 to 8 times, then I would 'loosen up' as I picked up the next 5 to 10 items. But that first one, oh was I stiff every time.`}</p>
      </li>
    </ul>
    <p>{`I found the more active I have been, climbing walls, doing obstacle courses, trampolining, bicycling it gradually seemed to get slowly worse. I didn't blame the exercise, I blamed me 'getting older' and 'being overweight.'`}</p>
    <p>{`So her offhand comment lead me to spend some time on the internet to see - is this normal for someone my age? Is this a problem? And probably most important, is there something I should be doing?`}</p>
    <p>{`I feared that part or all of the problem was because I was overweight, and it has been so hard to lose weight and keep it off that I have despaired of every getting into my best weight range. I feared that it is a problem that is 'just' because I'm old and there is nothing I can do. I feared that I should be doing more exercise. I feared that I should be doing less exercise.`}</p>
    <p>{`I find there is a common problem that a lot of people have that causes stiffness of joints.`}</p>
    <p>{`Some people recommend taking Glucosamine every day (or 3x's a day).`}</p>
    <p>{`Some recommend taking Glucosamine, but specifically made from glucosamine sulfate.`}</p>
    <p>{`Some recommend adding chondroitin and/or MSM.`}</p>
    <p>{`They all said that you need to take whichever of the above you have to take it for a minimum of 30 days before you will
notice any improvement. One source said it would take "up to 3 months" before the stiffness goes away, and all the bottles I read said I had to take 1 pill, 3 times a day, and then you need to keep
taking it 3 times a day for the rest of your life, because if you skip, you will take days to 'recover', to get back.`}</p>
    <p>{`So when I went to the store, I was thinking. Do I start with just any old Glucosamine, and then if no change after 30 days, switch to
the slightly more expensive (per gram of Glucosamine) from glucosamine sulfate,
and then if that didn't work, try 30 days adding chondroitin and if that still didn't work, try 30 days with MSM added
to the rest.`}</p>
    <p>{`Even the bottles, with the various options above, all said or implied, that I had to take the pills 3 times a day for 30 days before I would start to see results.`}</p>
    <p>{`So that meant I might be looking at 120 days, 4 months, before I saw any improvement, and I knew that I was not reliable and consistent
enough to do it 3 times a day, with meals (I often only have 1 meal a day), so even at the end of hte 120 days, I may
not see improvement because I didn't do it right.`}</p>
    <p>{`I thought, I'm not doing a scientific study here. I just want relief. So I decided to buy 1 bottle of Glucosamine from glucosamine sulfate, with chondroitin and MSM
from what I believed was a reputable manufacturer, the brand I choose was Jamieson.`}</p>
    <p>{`I went home and took my first pill with supper on about 2020.10.01.`}</p>
    <p>{`That night I wasn't stiff when I got off the chair I had been sitting on. I grinned to myself and said: Wow, the placebo effect of those pills is very strong.`}</p>
    <p>{`I took 3 pills the next day - two on an empty stomach (breaking one rule).`}</p>
    <p>{`That afternoon, after the 2nd pill, I drove to the city. Got out and forgot that I wasn't stiff.`}</p>
    <p>{`I drove home and got out, and after a couple steps realized - I wasn't stiff!`}</p>
    <p>{`That night, after the 3rd pill, I had no stiffness, I was up and down with the kids.`}</p>
    <p>{`Remember, I had been told by the highly reliable source (random article on the internet by someone who sounded smart, they used
a lot of jargon to say why it would work) that it would take 30 days to see a change.`}</p>
    <p>{`I thought about that and decided, ok, this is just a fluke, it isn't the Glucosamine from glucosamine sulfate, chondroitin and MSM  that is making
the difference, I just wasn't stiff today.`}</p>
    <p>{`Fast forward a week. I have been very reliable taking the "Glucosamine from glucosamine sulfate, chondroitin and MSM" at least
once every 2 days ;) some days I even took it twice in one day! The result: Most of the time I don't feel stiff. I'm bending
down to pick things up that In the past months I would have groaned to pick up. When I do feel stiff, it is just a mild
stiffness, that reminds me to go and take a "Glucosamine, with glucosamine sulfate, chondroitin and MSM" pill, and 6 hours
later, I'm not stiff at all again.`}</p>
    <p>{`Now let me be more technically correct. When I say 'not stiff at all', I'm exaggerating. I am still a little stiff. If I try to be completely honest, I'd say that 90% of the stiffness is gone and I'm left with 10% of the stiffness I used to have. But to understand my "I'm not stiff at all again" statement, you have to understand that I have been stiff for years, so in comparison, I really thought of it as no stiffness at all, until I 'sat back' and trying to be as accurate as I can.`}</p>
    <p>{`Now, let's be fair, while I am obese (technical definition), I have been fairly active the last few years. While I am almost
59 years old, my wife and I have 4 adult children and 4 young children (5-8 years old) that we are mom and dad to. While
I would not claim that I am a highly active person, I think it is fair to say that I am far more active than I was was 30
years ago.`}</p>
    <p>{`So it may be that my miraculous results are because I was a marginal case with only a small amount of stiffness. But I don't care. I am just happy that it is working for me.`}</p>
    <p>{`I wish I had know about this before. I'm glad I know about it now.`}</p>
    <p>{`So if you have stiff joints, don't be put off by the "30 days minimum to see results" type warnings. Sure, for you it might take 30 days, but maybe some of you will be like me and see dramatic results in just hours after you take your first pill.`}</p>
    <p>{`As far as I can see, reading the details on the internet, there is a fairly common problem that many people experience, and that "Glucosamine from glucosamine sulfate, chondroitin and MSM" can help 'fix'. I've decided to not go into the details of what this common problem is - simply because I would be regurgitating what others are saying, I wouldn't be speaking anything that I know with certainty.`}</p>
    <p>{`What I know with certainty, is that starting to use "Glucosamine from glucosamine sulfate, chondroitin and MSM" started to get rid of MY stiffness within hours, it didn't take 30 days. what I know for certainty is that taking "Glucosamine from glucosamine sulfate, chondroitin and MSM" is worth it for me to take away the "I feel old" feeling in my body.`}</p>
    <p>{`If after 30 days of using Glucosamine from glucosamine sulfate, chondroitin and MSM, if you haven't already, you should probably consider the other more serious problems that can cause stiffness of joints and check with your doctor. Of course, if you already knew you have one of those, you should be dealing with them. But, and I am not a doctor, so this is just my unscientific opinion: You might also have the problem that "Glucosamine, with glucosamine sulfate, chondroitin and MSM" can help with, so maybe you should also start taking "Glucosamine, with glucosamine sulfate, chondroitin and MSM".`}</p>
    <p>{`I might someday try one of the lessor combinations (missing some of the components,) but as for now, I plan on taking "Glucosamine from glucosamine sulfate, chondroitin and MSM" many times a week. Then if I find myself somewhere in the world where I can't find at a reasonable price Glucosamine with all those other things, then I'll try a 'lessor' option. In other words, I am not planning on making this a detailed scientific experiment. I'm happy that the results are worth it for me enough to keep taking "Glucosamine from glucosamine sulfate, chondroitin and MSM" up to 3 times a day, and whenever I feel stiff, use that as a reminder to try to take them 2 or 3 times a day for the next few days at least!`}</p>
    <p>{`Fast forward 10 or 11 days, 2020.10.16. I have been taking one or two pills a day, never remembered to take 3, for the last 7 to 10 days. I take them whenever I happen to remember, and I'm near the bottle, and I have some water (they are big pills, I can swallow them without water if I have to, but given my results - I don't have to!) I can't remember now the last time I noticed I was stiff. I think there were one or two times. If I really concentrate hard on the stiffness, I'd say there is 1 or 2% of the original stiffness left. Compare that to my technical report 11 days ago where I said that "no stiffness" meant 90% gone, 10% left. The other side benefit, I've noticed that I am a bit more willing to get up and play physically with my little kids, and I've been giving a lot more 'leg rides' (where they sit or stand on my ankles while I'm sitting and give them 'a ride'.)`}</p>
    <p>{`I am extremely pleased with the Jamieson Glucosamine Chondroitine MSM 1300mg (I don't get paid to say this) and while I suspect other brands might be just as good, I'm not switching given the success I've had unless they stop being available to me readily.`}</p>
    <h1>{`The rules I broke`}</h1>
    <p>{`I got 90% relief in less than 30 hours, and 99% in about 12 days, not 30 days.`}</p>
    <p>{`I take it whenever I remember, even on an empty stomach (otherwise I'd almost never take it, but I do try to) even just before going to bed. I did come up with a highly logical partial solution: I keep chocolate bars with the pills, that way I don't have to take the pills on an empty stomach. Update on about day 14, I no longer keep chocolate bars with the pills, but I do try a little bit to consume the pills after I've had `}<em parentName="p">{`something`}</em>{` to eat like real food or a glass of hot chocolate.`}</p>
    <h1>{`The conclusion of the matter`}</h1>
    <p>{`It is working for me. It isn't a perfect 100% solution, but it is very close. I feel younger. Now ... if I could just find some way to lose AND KEEP my weight off.`}</p>
    <p>{`Update 2020.11.03, about a month in. I have been taking one or two pills a day. I've had 3 times when I felt stiff so took a pill and several hours later was fine. Placebo effect? I don't know, but I do know this - if you are having stiffness in your joints, it is probably worth your while buying a bottle and seeing how it works for you!`}</p>
    <p>{`Update 2020.01.18, 3.5 months in, I have nearly finished one bottle of 120 pills, 10 left, so that means I've been consuming at a rate of just barely over 1 pill a day. Last week I had the back side of both knees stiff - I had done about 3 days of hard running in the basement with my kids playing catch, and I had only been having about 1 pill every 1 to 3 days. I switched to alternating between 2 pills a day and 1 a day (mostly a matter of forgetting) and got rid of most of the stiffness in about 3 to 4 days. So I bought a second bottle for when this one runs out.`}</p>
    <h1>{`non-obligatory notice`}</h1>
    <p>{`Note I make NOTHING from you following my advice so I don't have to give notice, however: These are all my opinions and no one paid me to post them. I put them up because I like to help other people.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      